<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import projectTotal from './projectTotal.vue'

const store = useStore()

const billsReceivedPercent = computed(() => {
  return (
    store.getters.projectTotals.totalRecInvoiced /
    store.state.projects.project.client_budget_pence
  ) * 100
})

const margins = computed(() => {
  const value =
    store.state.projects.project.client_budget_pence -
    store.getters.projectTotals.totalRecInvoiced

  const percent = (
    value / store.state.projects.project.client_budget_pence
  ) * 100

  return {
    value: value,
    percent: percent
  }
})

</script>

<template>
  <div class="container mx-auto bg-neutral-100 p-4 rounded-lg">
    <h2>Budget Totals</h2>
    <table class="w-2/3 table-fixed text-left">

      <projectTotal
        name="Estimated external costs"
        tooltip="This is the availble budget a Producer has to work with to realise the project"
        :value="store.getters.projectTotals.totalExternalCosts"
      />

      <projectTotal
        name="Production fee on ext costs @"
        tooltip="Worked out as a % of what we are planning to spend on external costs"
        :value="store.getters.projectTotals.productionFee"
        :percent="store.state.projects.project.production_fee_pc"
        fieldName="production_fee_pc"
        :percentEditable="!store.state.projects.project.rec_mode && store.state.projects.project.is_active"
      />

      <projectTotal
        name="Estimated internal costs"
        tooltip="
          These are internal costs covered by
          in-house services e.g. Editor at breakeven rates
        "
        :value="store.getters.projectTotals.totalInternalBECost"
      />

      <projectTotal
        name="Estimated bill to client"
        tooltip="What we need to bill as a business"
        :value="store.getters.projectTotals.totalBillToClient"
        rowStyles="font-extrabold"
      />

      <projectTotal
        name="Optional client discount"
        tooltip="Make sure the client knows they are receiving a discount"
        :value="store.getters.projectTotals.totalClientDiscount"
        :percent="store.state.projects.project.client_discount_pc"
        :percentEditable="!store.state.projects.project.rec_mode && store.state.projects.project.is_active"
        fieldName="client_discount_pc"
      />

      <projectTotal
        name="Estimated margin"
        tooltip="Total estimated margin adding together 'profit on costs' & 'production fee'"
        :value="
          store.getters.projectTotals.estimatedProfit ?
          store.getters.projectTotals.estimatedProfit.value :
          ''
        "
        :percent="
          store.getters.projectTotals.estimatedProfit ?
          store.getters.projectTotals.estimatedProfit.percentage :
          ''
        "
        rowStyles="font-extrabold"
      />

      <projectTotal
        name="Client's available budget"
        tooltip="
          This is what's left (over or under)
          that can be accessed (Only green means it's a go)
        "
        :value="store.state.projects.project.client_budget_pence"
      />

      <projectTotal
        name="Difference/achievability"
        tooltip="Actual costs to us after all internal and external cost are reconciled"
        :value="store.getters.projectTotals.difference"
        rowStyles="font-extrabold"
        :valueStyles="{
          'text-green-500': store.getters.projectTotals.difference > 0,
          'text-red-500': store.getters.projectTotals.difference < 100
        }"
      />

      <projectTotal
        v-if="store.state.projects.project.rec_mode"
        name="Bills received"
        tooltip="Actual costs to us after all internal and external cost are reconciled"
        :value="store.getters.projectTotals.totalRecInvoiced"
        :percent="billsReceivedPercent"
        :rowStyles="{
            'font-extrabold': true,
            'text-green-500': store.getters.projectRecsPaid,
            'text-yellow-500': store.getters.projectRecsPaid === false
          }"
      />

      <projectTotal
        v-if="store.state.projects.project.rec_mode"
        name="Margin"
        tooltip="Amount we've charged the client, minus the 'Actual costs' to us i.e. Margin"
        :value="margins.value"
        :percent="margins.percent"
        rowStyles="font-extrabold"
      />

    </table>
  </div>
</template>
