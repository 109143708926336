<script setup>
import { defineProps } from 'vue'
import { penceToPounds } from '../js/utils'
import EditableLabel from '@/components/editableLabel.vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  rowStyles: String,
  name: String,
  tooltip: String,
  value: Number,
  valueStyles: String,
  percent: Number,
  fieldName: String,
  percentEditable: Boolean
})

/**
 * round floating point percentage to 2 dp
 * @param {Number} percent Int or Float percentage
 * @returns {Float} percentage with max 2 dp
 */
function roundPercent (percent) {
  if (Number.isInteger(percent)) {
    return percent
  } else {
    return Number(percent).toFixed(2)
  }
}

/**
 *  Updates project on backend with supplied total values
 * @param {*} fieldName
 * @param {*} value
 */
function updateProject (fieldName, value) {
  /**
   * Validates the value being sent to the backend is
   * a valid positive Int between 0 and 100 inclusive
   * @param {*} value
   */
  function isValidPosInt (value) {
    return value <= 100 &&
      value >= 0 &&
      !isNaN(value) &&
      Number.isInteger(Number(value))
  }

  if (value) {
    const updateObj = {}
    if (
      fieldName === 'production_fee_pc' || fieldName === 'client_discount_pc'
    ) {
      if (!isValidPosInt(value)) {
        store.commit('addAlert', {
          status: 'warning',
          message: 'Could not set percentage, please enter a valid number.',
          timer: 3000
        })
      } else {
        updateObj[fieldName] = value
      }
    } else {
      updateObj[fieldName] = value
    }
    store.commit('updateProject', updateObj)
    store.dispatch('patchProject')
    store.dispatch('calculateAllTotals')
  }
}

</script>
<template>
  <tr class="has-tooltip" :class="rowStyles">
    <th>
      <p>{{ props.name }}</p>
      <div
        v-if="props.tooltip && props.tooltip"
        class="
                tooltip bg-neutral-700
                text-neutral-100 p-1 rounded
                mt-2 ml-96
              "
      >
      {{ props.name }}
      </div>
    </th>

    <td>
      <p :class="props.valueStyles">
        {{ penceToPounds(props.value) }}
      </p>
    </td>

    <td v-if="props.percent !== undefined">
      <EditableLabel
        v-if="props.percentEditable"
        type="number"
        :modelValue="props.percent"
        @update:modelValue="updateProject(props.fieldName, $event)"
      >
        <p>{{ roundPercent(props.percent) }}%</p>
      </EditableLabel>
      <p v-else>{{ roundPercent(props.percent) }}%</p>
    </td>
  </tr>
</template>
