<template>
    <Page title="Component Reference">
        <p>Below are examples of all components avaliable in the app</p>

        <!-- Buttons -->
        <h2>Buttons</h2>
        <Button buttontype="background">Primary Background</Button>
        <Button buttontype="border">Primary Border</Button>
        <Button buttontype="neutral-border">Neutral Border</Button>
        <Button>Default</Button>

        <!-- Icons -->
        <h2>Icons</h2>
        <Icon name="close"/>
        <Icon name="chevron-right"/>
        <Icon name="chevron-left"/>
        <Icon name="chevron-down"/>
        <Icon name="burger"/>

        <!-- Project Header -->
        <h2>Project Header</h2>
        <div class="container mx-auto bg-neutral-100 p-4 rounded-lg">
          <ProjectHeader v-bind:project="project"/>
        </div>
        <!-- Department -->
        <h2>Department</h2>
        <Department v-bind:department="project.departments[0]"/>

        <!-- Category -->
        <h2>Category</h2>
        <Category v-bind:category="project.departments[0].categories[0]"/>

        <!-- Project Totals -->
        <h2>Project totals</h2>
        <projectTotals />

    </Page>
</template>

<script>
import Button from '@/components/button.vue'
import Category from '@/components/category.vue'
import Department from '@/components/department.vue'
import Icon from '@/components/icon.vue'
import Page from '@/components/page.vue'
import ProjectHeader from '@/components/projectHeader.vue'
import projectTotals from '@/components/projectTotals/projectTotals.vue'

export default {
  name: 'ComponentRef',
  components: {
    Button,
    Category,
    Department,
    Icon,
    Page,
    ProjectHeader,
    projectTotals
  },
  data () {
    return {
      // Representative data for a single project entry
      project: {
        client: 'Client',
        client_budget_pence: 10000000,
        client_discount_pc: 10,
        deliverables_summary: `
          Apple or an Apple Authorized Service Provider
          will service eligible MacBook, MacBook Air,
          and MacBook Pro keyboards, free of charge.
          The type of service will be determined after
          the keyboard is examined and may involve the
           replacement of one or more keys or the
           whole keyboard
        `,
        departments: [
          {
            categories: [
              {
                id: 17,
                name: 'Costs',
                projectlineitems: [
                  {
                    project: 10,
                    line_item: {
                      id: 275,
                      category: {
                        id: 17,
                        department: {
                          id: 5,
                          name: 'Art'
                        },
                        name: 'Costs'
                      },
                      unit: {
                        id: 1,
                        name: 'Day'
                      },
                      added_datetime: '2021-10-05T08:52:09.975971Z',
                      modified_datetime: '2021-10-05T08:52:09.975993Z',
                      name: 'Wardrobe - Extras',
                      rate_pence: 30000,
                      apa_ot_grade: {
                        grade: 1,
                        coefficient: 125
                      },
                      night_ot_rate: {
                        coefficient: 300
                      },
                      equity_ot_req: false,
                      added_user: 1,
                      modified_user: 1
                    },
                    internal_cost_pence: 120_000,
                    external_cost_pence: 180_000,
                    prep_time_days: 7,
                    work_time_days: 8,
                    std_ot_hours: 10,
                    night_ot_hours: 12,
                    quantity: 10,
                    notes: null,
                    // Only used if a new ProjectLineItem is created
                    category: null,
                    name: null,
                    rate_pence: null,
                    unit: null
                  }
                ]
              }
            ],
            id: 1,
            name: 'Art'
          }
        ],
        description: `
          Please choose one of the options below
           for service. Your Mac notebook will be examined prior 
           to any service to verify that it is eligible for this program.
        `,
        is_active: true,
        production_fee_pc: 10,
        point_of_contact: 'Jeff Goldblum',
        reference: 'client-test-project',
        title: 'Test Project'
      }
    }
  }
}
</script>
